html, body {
    height: 100%;
    font-family: Arial, "Helvetica Neue", sans-serif;
    font-size: 15px;
}

#root {
    height: 100%;
}

.w-330 {
    max-width: 330px;
}

.w-900 {
    max-width: 900px;
}


.signin-alt {
    margin-top: 5px;
    margin-bottom: 5px;
}

.fs-larger {
    font-size: larger;
}

.csmall {
    font-size: 12px;
}

.highlight {
    background-color: lightyellow;
}

